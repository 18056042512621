import { LOGIN_TOKEN } from '../constants';

export const getLoginToken = () => {
  return localStorage.getItem(LOGIN_TOKEN);
};

export const getDomainTalent = () => {
  const host = window.location.host;
  if (host === 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN) {
    const domain = host.replace('www.','');
    return domain;
  } else {
    return host;
  }
};

export const checkNameTalent = () => {
  const domain = window.location.host;
  switch (domain) {
    case process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
      return {
        name: 'チョ・インソン',
        talentName: 'チョ・インソン',
        nameEng: 'ZO IN SUNG',
        nameLower: 'zo-insung',
        logo: process.env.REACT_APP_ZIS_LOGO,
        merchant_id: process.env.REACT_APP_ZIS_MERCHANT_ID,
        token: process.env.REACT_APP_ZIS_TOKEN,
        logo_footer: process.env.REACT_APP_ZIS_LOGO_FOOTER,
        link_logo_footer: 'https://www.iok.co.kr'
      };
    case 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
      return {
        name: 'チョ・インソン',
        talentName: 'チョ・インソン',
        nameEng: 'ZO IN SUNG',
        nameLower: 'zo-insung',
        logo: process.env.REACT_APP_ZIS_LOGO,
        merchant_id: process.env.REACT_APP_ZIS_MERCHANT_ID,
        token: process.env.REACT_APP_ZIS_TOKEN,
        logo_footer: process.env.REACT_APP_ZIS_LOGO_FOOTER,
        link_logo_footer: 'https://www.iok.co.kr'
      };
    case process.env.REACT_APP_TALENT_KIM_NAN_GIL_DOMAIN:
      return {
        name: 'キムナムギル',
        talentName: 'キム・ナムギル',
        nameEng: 'KIM NAM GIL',
        nameLower: 'kimnamgil',
        logo: process.env.REACT_APP_KNG_LOGO,
        merchant_id: process.env.REACT_APP_KNG_MERCHANT_ID,
        token: process.env.REACT_APP_KNG_TOKEN,
        logo_footer: process.env.REACT_APP_KNG_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
    case process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
      return {
        name: 'イ・ジョンソク',
        talentName: 'イ・ジョンソク',
        nameEng: 'LEE JONG SUK',
        nameLower: 'lee-jongsuk',
        logo: process.env.REACT_APP_LJS_LOGO,
        merchant_id: process.env.REACT_APP_LJS_MERCHANT_ID,
        token: process.env.REACT_APP_LJS_TOKEN,
        logo_footer: process.env.REACT_APP_LJS_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
    case 'www.' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
      return {
        name: 'イ・ジョンソク',
        talentName: 'イ・ジョンソク',
        nameEng: 'LEE JONG SUK',
        nameLower: 'lee-jongsuk',
        logo: process.env.REACT_APP_LJS_LOGO,
        merchant_id: process.env.REACT_APP_LJS_MERCHANT_ID,
        token: process.env.REACT_APP_LJS_TOKEN,
        logo_footer: process.env.REACT_APP_LJS_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
    case process.env.REACT_APP_TALENT_LEE_SEUNG_GI_DOMAIN:
      return {
        name: 'イスンギ',
        talentName: 'イ・スンギ',
        nameEng: 'LEE SEUNG GI',
        nameLower: 'leeseunggi',
        logo: process.env.REACT_APP_LSG_LOGO,
        merchant_id: process.env.REACT_APP_LSG_MERCHANT_ID,
        token: process.env.REACT_APP_LSG_TOKEN,
        logo_footer: process.env.REACT_APP_LSG_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
    case process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN:
      return {
        name: 'ソン・ジュンギ',
        talentName: 'ソン・ジュンギ',
        nameEng: 'SONG JOONG KI',
        nameLower: 'song-joongki',
        logo: process.env.REACT_APP_SJK_LOGO,
        merchant_id: process.env.REACT_APP_SJK_MERCHANT_ID,
        token: process.env.REACT_APP_SJK_TOKEN,
        logo_footer: process.env.REACT_APP_SJK_LOGO_FOOTER,
        link_logo_footer: 'https://www.highziumstudio.com'
      };
    case process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN:
      return {
        name: 'キム・ヨンデ',
        talentName: 'キム・ヨンデ',
        nameEng: 'KIM YOUNG DAE',
        nameLower: 'kim-youngdae',
        logo: process.env.REACT_APP_KYD_LOGO,
        merchant_id: process.env.REACT_APP_KYD_MERCHANT_ID,
        token: process.env.REACT_APP_KYD_TOKEN,
        logo_footer: process.env.REACT_APP_KYD_LOGO_FOOTER,
        link_logo_footer: 'https://outerkorea.com'
      };
    case process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN:
      return {
        name: 'ユ・スンホ',
        talentName: 'ユ・スンホ',
        nameEng: 'YOO SEUNG HO',
        nameLower: 'yoo-seungho',
        logo: process.env.REACT_APP_YSH_LOGO,
        merchant_id: process.env.REACT_APP_YSH_MERCHANT_ID,
        token: process.env.REACT_APP_YSH_TOKEN,
        logo_footer: process.env.REACT_APP_YSH_LOGO_FOOTER,
        link_logo_footer: null
      };
    case process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN:
      return {
        name: 'キム・ジウォン',
        talentName: 'キム・ジウォン',
        nameEng: 'KIM JI WON',
        nameLower: 'kim-jiwon',
        logo: process.env.REACT_APP_KJW_LOGO,
        merchant_id: process.env.REACT_APP_KJW_MERCHANT_ID,
        token: process.env.REACT_APP_KJW_TOKEN,
        logo_footer: process.env.REACT_APP_KJW_LOGO_FOOTER,
        link_logo_footer: 'https://www.highziumstudio.com/'
      };
    case process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN:
      return {
        name: 'パク・ミニョン',
        talentName: 'パク・ミニョン',
        nameEng: 'PARK MIN YOUNG',
        nameLower: 'park-minyoung',
        logo: process.env.REACT_APP_PMY_LOGO,
        merchant_id: process.env.REACT_APP_PMY_MERCHANT_ID,
        token: process.env.REACT_APP_PMY_TOKEN,
        logo_footer: null,
        link_logo_footer: ''
      };
    case process.env.REACT_APP_TALENT_LEE_MIN_KI_DOMAIN:
      return {
        name: 'イミンギ',
        talentName: 'イ・ミンギ',
        nameEng: 'LEE MIN KI',
        nameLower: 'leeminki',
        logo: process.env.REACT_APP_LMK_LOGO,
        merchant_id: process.env.REACT_APP_LMK_MERCHANT_ID,
        token: process.env.REACT_APP_LMK_TOKEN,
        logo_footer: process.env.REACT_APP_LMK_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
	  case process.env.REACT_APP_TALENT_YOOK_SUNGJAE_DOMAIN:
	    return {
	      name: 'ユク・ソンジェ',
	      talentName: 'ユク・ソンジェ',
	      nameEng: 'YOOK SUNG JAE',
	      nameLower: 'yook-sungjae',
	      logo: process.env.REACT_APP_YSJ_LOGO,
	      merchant_id: process.env.REACT_APP_YSJ_MERCHANT_ID,
	      token: process.env.REACT_APP_YSJ_TOKEN,
	      logo_footer: process.env.REACT_APP_YSJ_LOGO_FOOTER,
        link_logo_footer: 'https://cafe.daum.net/yooksungjaeofficial'
	    };
    default:
      return {
        name: 'チャン・グンソク',
        talentName: 'チャン・グンソク',
        nameEng: 'JANG KEUN SUK',
        nameLower: 'jangkeunsuk',
        logo: process.env.REACT_APP_JKS_LOGO,
        merchant_id: process.env.REACT_APP_JKS_MERCHANT_ID,
        token: process.env.REACT_APP_JKS_TOKEN,
        logo_footer: process.env.REACT_APP_JKS_LOGO_FOOTER,
        link_logo_footer: 'https://frau-inter.co.jp/media/'
      };
  }
};
