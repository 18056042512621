import _ from 'lodash';
import clsx from 'clsx';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormHelperText
} from '@material-ui/core';
import postal_code from 'japan-postal-code';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-mobile-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

import { ROUTES_AUTHEN } from 'src/constants';
import { settingSelector } from 'src/slices/settings';
import { authSelector, setRegisterInfo } from 'src/slices/auth';
import AutoPaymentDialog from 'src/components/AutoPaymentDialog';
import { checkDuplicateNickname } from 'src/services/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  registerSubTitle: {
    margin: '70px 0 40px',
    fontSize: '0.9rem'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: '0.9rem'
  },
  inputContainer: {
    marginRight: 16
  },
  inputBirthday: {
    marginRight: 4
  },
  wrapInput: {
    color: 'black',
    height: 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px'
    }
  },
  input25: {
    width: '28%',
    [theme.breakpoints.down(700)]: {
      width: '50%'
    }
  },
  input40: {
    width: '40%',
    [theme.breakpoints.down(700)]: {
      width: '75%'
    }
  },
  input75: {
    width: '50%',
    [theme.breakpoints.down(700)]: {
      width: '75%'
    }
  },
  registerButton: {
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 100px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px'
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayFlexSP: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  labelContainer: {
    width: '30%',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 6
  },
  labelRequire: {
    backgroundColor: '#E02323',
    marginRight: 10,
    padding: '0 14px',
    color: '#ffffff',
    borderRadius: 12
  },
  labelBirthday: {
    marginRight: 20
  },
  textError: {
    color: '#FF0000',
    [theme.breakpoints.down(700)]: {
      marginTop: 8
    }
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  errorMessage: {
    marginTop: '-2rem',
    marginBottom: 10,
    color: 'red',
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem'
      }
    },
    '& .datepicker': {
      position: 'unset',
      width: '19.5rem',
      marginTop: '1rem'
    }
  },
  birthdaySP: {
    [theme.breakpoints.down(700)]: {
      marginBottom: '3rem'
    }
  },
  radio: {
    fontSize: '1.3rem',
    color: '#333333',
    '&$checked': {
      color: '#333333'
    }
  },
  checked: {},
  inputRight: {
    display: 'flex',
    width: '68%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  dateSelect: {
    border: 'none',
    padding: '0.5rem 0 0.5rem 0.5rem',
    marginRight: 7,
    fontSize: 16,
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    borderRadius: 0,
    backgroundColor: 'white',
    color: 'black',
    '& option:first-child': {
      display: 'none'
    }
  },
  yearSelect: {
    width: '5rem'
  },
  daySelect: {
    width: '3.5rem'
  },
  errorContainer: {
    [theme.breakpoints.down(900)]: {
      display: 'none'
    }
  }
}));

function RegistrationInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const settings = useSelector(settingSelector);
  const { registerInfo } = auth;

  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [disabledButton, setDisableButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const [showError, setShowError] = useState(false);
  const [date, setDate] = useState({
    year: registerInfo?.birthday
      ? moment(registerInfo?.birthday, 'YYYY-MM-DD').year()
      : null,
    month: registerInfo?.birthday
      ? moment(registerInfo?.birthday, 'YYYY-MM-DD').month()
      : null,
    day: registerInfo?.birthday
      ? moment(registerInfo?.birthday, 'YYYY-MM-DD').format('D')
      : null
  });

  const inputTel2 = useRef();
  const inputTel3 = useRef();
  const inputAddress1 = useRef();
  const inputPassword = useRef();
  const inputNickname = useRef();

  const formik = useFormik({
    initialValues: _.isEmpty(registerInfo)
      ? {
          first_name: '',
          family_name: '',
          first_furigana: '',
          family_furigana: '',
          nickname: '',
          birthday: moment(new Date(), 'YYYY/MM/DD').format('YYYY/MM/DD'),
          post_code: '',
          address1: '',
          address2: '',
          address3: '',
          tel1: '',
          tel2: '',
          tel3: '',
          gender: '2',
          password: '',
          password_confirmation: '',
          payment_type: '1',
          email: auth?.emailSendVerify
        }
      : {
          first_name: registerInfo.first_name,
          family_name: registerInfo.family_name,
          first_furigana: registerInfo.first_furigana,
          family_furigana: registerInfo.family_furigana,
          nickname: registerInfo.nickname,
          birthday: moment(registerInfo.birthday, 'YYYY/MM/DD').format(
            'YYYY/MM/DD'
          ),
          post_code: registerInfo.post_code,
          address1: registerInfo.address1,
          address2: registerInfo.address2,
          address3: registerInfo.address3,
          tel1: registerInfo.tel1,
          tel2: registerInfo.tel2,
          tel3: registerInfo.tel3,
          gender: registerInfo.gender,
          password: registerInfo.password,
          password_confirmation: registerInfo.password_confirmation,
          payment_type: registerInfo.payment_type,
          email: auth?.emailSendVerify
        },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().max(45, '全角45文字以内').required('必須'),
      family_name: Yup.string().max(45, '全角45文字以内').required('必須'),
      first_furigana: Yup.string()
        .matches(/^[ァ-ヶー　]*$/, 'カタカナの全角文字で入力してください。')
        .max(6, '全角6文字以内')
        .required('必須'),
      family_furigana: Yup.string()
        .matches(/^[ァ-ヶー　]*$/, 'カタカナの全角文字で入力してください。')
        .max(6, '全角6文字以内')
        .required('必須'),
      nickname: Yup.string().max(45, '全角45文字以内').required('必須'),
      post_code: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .max(7)
        .required('必須'),
      tel1: Yup.string()
        .matches(/^\d+$/, 'ハイフンなしの半角数字で入力してください')
        .required('必須'),
      tel2: Yup.string()
        .matches(/^\d+$/, 'ハイフンなしの半角数字で入力してください')
        .required('必須'),
      tel3: Yup.string()
        .matches(/^\d+$/, 'ハイフンなしの半角数字で入力してください')
        .required('必須'),
      address1: Yup.string().required('必須'),
      address2: Yup.string().required('必須'),
      address3: Yup.string().required('必須'),
      password: Yup.string()
        .min(6, '半角英数字6文字以上')
        .max(45, '半角英数字45文字以内')
        .required('必須'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'パスワードが一致しません')
        .required('必須')
    }),
    onSubmit: async (values) => {
      setDisableButton(true);
      const { payload } = await dispatch(
        checkDuplicateNickname({
          first_name: values.first_name,
          family_name: values.family_name,
          first_furigana: values.first_furigana,
          family_furigana: values.family_furigana,
          nickname: values.nickname,
          // birthday:
          //   date.year !== '' && window.innerWidth > 900
          //     ? `${date.year}-${parseInt(date.month) + 1}-${date.day}`
          //     : moment(values.birthday).format('YYYY-MM-DD'),
          birthday: `${date.year}-${parseInt(date.month) + 1}-${date.day}`,
          post_code: values.post_code,
          address1: values?.address1,
          address2: values?.address2,
          address3: values?.address3,
          tel1: values.tel1,
          tel2: values.tel2,
          tel3: values.tel3,
          gender: values.gender,
          password: values.password,
          password_confirmation: values.password_confirmation,
          payment_type: values.payment_type,
          email: auth?.emailSendVerify
        })
      );

      setFocus(false);
      if (payload?.msg === 'Success') {
        dispatch(
          setRegisterInfo({
            first_name: values.first_name,
            family_name: values.family_name,
            first_furigana: values.first_furigana,
            family_furigana: values.family_furigana,
            nickname: values.nickname,
            // birthday:
            //   date.year !== '' && window.innerWidth > 900
            //     ? `${date.year}-${parseInt(date.month) + 1}-${date.day}`
            //     : moment(values.birthday).format('YYYY-MM-DD'),
            birthday: `${date.year}-${parseInt(date.month) + 1}-${date.day}`,
            post_code: values.post_code,
            address1: values?.address1,
            address2: values?.address2,
            address3: values?.address3,
            tel1: values.tel1,
            tel2: values.tel2,
            tel3: values.tel3,
            gender: values.gender,
            password: values.password,
            password_confirmation: values.password_confirmation,
            payment_type: values.payment_type,
            email: auth?.emailSendVerify
          })
        );
        navigate(ROUTES_AUTHEN.REGISTER_INFO_CONFIRM);
      } else {
        setDisableButton(false);
        if (payload?.errors?.nickname) {
          setTitleDialog(payload?.errors?.nickname[0]);
        } else {
          setTitleDialog('エラーがあります');
        }
        setOpenDialog(true);
      }
    }
  });

  useEffect(() => {
    if (focus) {
      inputNickname.current.focus();
    }
  }, [focus]);

  const getPostCode = (postCode) => {
    postal_code.get(postCode, (address) => {
      formik.setFieldValue('address1', address.prefecture);
      formik.setFieldValue(
        'address2',
        address.city + address.area + address.street
      );
    });
  };

  const handleSelectOnMobile = (selectDate) => {
    let formattedDate = moment(selectDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
    formik.setFieldValue('birthday', formattedDate);
    document.getElementById('root').style.overflow = 'scroll';
    setIsOpen(false);
  };

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: '年',
      step: 1
    },
    month: {
      format: 'MM',
      caption: '月',
      step: 1
    },
    date: {
      format: 'DD',
      caption: '日',
      step: 1
    }
  };

  useMemo(() => {
    if (date.year === null || date.month === null || date.day === null) {
      return;
    } else {
      setShowError(false);
    }
  }, [date]);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
          <Box className={classes.wrapTitle}>
            <Typography className={classes.registerTitleText}>
              会員情報の入力
            </Typography>
            <Box className={classes.wrapTitleRight}>
              <Typography className={clsx(classes.registrationTitleActive)}>
                会員情報入力
              </Typography>
              <Typography className={classes.titleRightText}>
                入力内容確認
              </Typography>
              <Typography>会員登録決済</Typography>
            </Box>
          </Box>
          <Typography className={classes.registerSubTitle}>
            「<a href="/terms">会員規約</a>
            」および「
            <a
              href="https://www.frau-inter.co.jp/register_privacypolicy/"
              target="blank"
            >
              個人情報の取り扱いについて
            </a>
            」を必ずお読みください。
            同意いただける場合は、下記のフォームに必要事項を入力し「同意して確認ページへ」ボタンを押してください。
          </Typography>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              会員情報入力
            </Typography>
          </Box>

          <form
            onSubmit={(e) => {
              formik.handleSubmit(e);

              if (
                date.year === null ||
                date.month === null ||
                date.day === null
              ) {
                setShowError(true);
              } else {
                setShowError(false);
              }
            }}
          >
            <Box pb={5} className={classes.registerBorderBottom}>
              {/* First name */}
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    お名前
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={classes.inputRight}>
                  <TextField
                    name="family_name"
                    className={clsx(classes.inputContainer)}
                    error={Boolean(
                      formik.touched.family_name && formik.errors.family_name
                    )}
                    helperText={
                      formik.touched.family_name && formik.errors.family_name
                    }
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    value={formik.values.family_name}
                    onChange={formik.handleChange}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <TextField
                    name="first_name"
                    fullWidth
                    error={Boolean(
                      formik.touched.first_name && formik.errors.first_name
                    )}
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                </Box>
              </Box>
              {/* Furigana name */}
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    フリガナ
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={classes.inputRight}>
                  <TextField
                    name="family_furigana"
                    className={clsx(classes.inputContainer)}
                    error={Boolean(
                      formik.touched.family_furigana &&
                        formik.errors.family_furigana
                    )}
                    helperText={
                      formik.touched.family_furigana &&
                      formik.errors.family_furigana
                    }
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    value={formik.values.family_furigana}
                    onChange={formik.handleChange}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <TextField
                    name="first_furigana"
                    error={Boolean(
                      formik.touched.first_furigana &&
                        formik.errors.first_furigana
                    )}
                    helperText={
                      formik.touched.first_furigana &&
                      formik.errors.first_furigana
                    }
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_furigana}
                    onChange={formik.handleChange}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                </Box>
              </Box>
              {/* Nickname */}
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    ニックネーム
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box display={'flex'} flexGrow={1}>
                  <TextField
                    name="nickname"
                    inputRef={inputNickname}
                    className={clsx(classes.inputContainer)}
                    fullWidth
                    value={formik.values.nickname}
                    error={Boolean(
                      formik.touched.nickname && formik.errors.nickname
                    )}
                    helperText={
                      formik.touched.nickname && formik.errors.nickname
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                      className: [classes.wrapInput, classes.input75]
                    }}
                  />
                </Box>
              </Box>
              {/* Birthday */}
              <Box className={clsx(classes.displayFlex, classes.birthdaySP)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    生年月日
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>

                <Box display={'flex'} flexGrow={1} alignItems="center">
                  {false ? (
                    // <TextField
                    //   name="birthday"
                    //   className={clsx(classes.inputContainer)}
                    //   fullWidth
                    //   value={formik.values.birthday}
                    //   onFocus={() => {
                    //     setIsOpen(true);
                    //     document.getElementById('root').style.overflow =
                    //       'hidden';
                    //   }}
                    //   InputProps={{
                    //     readOnly: true,
                    //     disableUnderline: true,
                    //     className: [classes.wrapInput, classes.input75]
                    //   }}
                    // />
                    <></>
                  ) : (
                    <>
                      <YearPicker
                        reverse
                        end={new Date().getFullYear() - 1}
                        value={date.year}
                        onChange={(year) => {
                          setDate((prev) => ({ ...prev, year }));
                          if (
                            date.year === null ||
                            date.month === null ||
                            date.day === null
                          ) {
                            setShowError(true);
                          } else {
                            setShowError(false);
                          }
                        }}
                        id={'year'}
                        classes={clsx(classes.dateSelect, classes.yearSelect)}
                      />
                      <Typography className={clsx(classes.labelBirthday)}>
                        年
                      </Typography>
                      <MonthPicker
                        numeric
                        endYearGiven
                        year={date.year}
                        value={date.month}
                        onChange={(month) => {
                          setDate((prev) => ({ ...prev, month }));
                          if (
                            date.year === null ||
                            date.month === null ||
                            date.day === null
                          ) {
                            setShowError(true);
                          } else {
                            setShowError(false);
                          }
                        }}
                        id={'month'}
                        classes={clsx(classes.dateSelect, classes.daySelect)}
                      />
                      <Typography className={clsx(classes.labelBirthday)}>
                        月
                      </Typography>
                      <DayPicker
                        year={date.year}
                        month={date.month}
                        endYearGiven
                        value={date.day}
                        onChange={(day) => {
                          setDate((prev) => ({ ...prev, day }));

                          if (
                            date.year === null ||
                            date.month === null ||
                            date.day === null
                          ) {
                            setShowError(true);
                          } else {
                            setShowError(false);
                          }
                        }}
                        id={'day'}
                        classes={clsx(classes.dateSelect, classes.daySelect)}
                      />
                      <Typography className={clsx(classes.labelBirthday)}>
                        日
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>

              <Box
                mb={3}
                className={clsx(classes.displayFlex, classes.errorContainer)}
              >
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography
                    className={clsx(classes.label)}
                    style={{ marginRight: 78 }}
                  ></Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  {showError && (
                    <FormHelperText className={classes.textError}>
                      必須
                    </FormHelperText>
                  )}
                </Box>
              </Box>

              {/* Post code */}
              <Box
                mb={1}
                className={clsx(classes.displayFlex)}
                style={{ alignItems: 'flex-end' }}
              >
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    郵便番号／住所
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box display={'flex'} flexGrow={1}>
                  <Box flexGrow={1}>
                    <Typography>郵便番号</Typography>
                    <TextField
                      name="post_code"
                      error={Boolean(formik.errors.post_code)}
                      helperText={formik.errors.post_code}
                      onFocus={() => setOpenSelectBirthdayOnPc(false)}
                      value={formik.values.post_code}
                      type="tel"
                      className={clsx(classes.inputContainer, classes.input25)}
                      onChange={(event) => {
                        if (event.target.value.length <= 7) {
                          formik.handleChange(event);
                          getPostCode(event.target.value);

                          if (event.target.value.length === 7) {
                            inputAddress1.current.focus();
                          }
                        }
                      }}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        className: classes.wrapInput
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {/* Address */}
              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box
                  display={'flex'}
                  className={clsx(classes.labelContainer)}
                ></Box>
                <Box className={classes.inputRight}>
                  <Box flexGrow={1}>
                    <Typography>都道府県</Typography>
                    <TextField
                      name="address1"
                      className={clsx(classes.inputContainer)}
                      error={Boolean(
                        formik.touched.address1 && formik.errors.address1
                      )}
                      helperText={
                        formik.touched.address1 && formik.errors.address1
                      }
                      onFocus={() => setOpenSelectBirthdayOnPc(false)}
                      inputRef={inputAddress1}
                      onBlur={formik.handleBlur}
                      value={formik.values.address1}
                      onChange={formik.handleChange}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        className: classes.wrapInput
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box
                  display={'flex'}
                  className={clsx(classes.labelContainer)}
                ></Box>
                <Box className={classes.inputRight}>
                  <Box flexGrow={1}>
                    <Typography>市区町村</Typography>
                    <TextField
                      name="address2"
                      className={clsx(classes.inputContainer)}
                      fullWidth
                      error={Boolean(
                        formik.touched.address2 && formik.errors.address2
                      )}
                      helperText={
                        formik.touched.address2 && formik.errors.address2
                      }
                      onFocus={() => setOpenSelectBirthdayOnPc(false)}
                      onBlur={formik.handleBlur}
                      value={formik.values.address2}
                      onChange={formik.handleChange}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.wrapInput
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box
                  display={'flex'}
                  className={clsx(classes.labelContainer)}
                ></Box>
                <Box className={classes.inputRight}>
                  <Box flexGrow={1}>
                    <Typography>番地・建物名</Typography>
                    <TextField
                      name="address3"
                      error={Boolean(
                        formik.touched.address3 && formik.errors.address3
                      )}
                      helperText={
                        formik.touched.address3 && formik.errors.address3
                      }
                      onFocus={() => setOpenSelectBirthdayOnPc(false)}
                      onBlur={formik.handleBlur}
                      value={formik.values.address3}
                      onChange={formik.handleChange}
                      className={clsx(classes.inputContainer)}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        className: classes.wrapInput
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {/* Phone */}
              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    電話番号
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box
                  className={clsx(
                    classes.displayFlex,
                    classes.displayFlexSP,
                    classes.inputRight
                  )}
                >
                  <TextField
                    name="tel1"
                    error={Boolean(formik.errors.tel1)}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    value={formik.values.tel1}
                    onChange={(event) => {
                      if (event.target.value.length <= 3) {
                        formik.handleChange(event);
                      }
                      if (event.target.value.length === 3) {
                        inputTel2.current.focus();
                      }
                    }}
                    type="tel"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <Typography style={{ margin: '0 10px' }}>⎯</Typography>
                  <TextField
                    name="tel2"
                    error={Boolean(formik.errors.tel2)}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    value={formik.values.tel2}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                      }
                      if (event.target.value.length === 4) {
                        inputTel3.current.focus();
                      }
                    }}
                    type="tel"
                    inputRef={inputTel2}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <Typography style={{ margin: '0 10px' }}>⎯</Typography>
                  <TextField
                    name="tel3"
                    error={Boolean(formik.errors.tel3)}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    value={formik.values.tel3}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                      }
                      if (event.target.value.length === 4) {
                        inputPassword.current.focus();
                      }
                    }}
                    type="tel"
                    inputRef={inputTel3}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                </Box>
              </Box>

              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography
                    className={clsx(classes.label)}
                    style={{ marginRight: 78 }}
                  ></Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  {formik.errors.tel1 ? (
                    <FormHelperText className={classes.textError}>
                      {formik.errors.tel1}
                    </FormHelperText>
                  ) : formik.errors.tel2 ? (
                    <FormHelperText className={classes.textError}>
                      {formik.errors.tel2}
                    </FormHelperText>
                  ) : (
                    <FormHelperText className={classes.textError}>
                      {formik.errors.tel3}
                    </FormHelperText>
                  )}
                </Box>
              </Box>

              {/* Email */}
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography
                    className={clsx(classes.label)}
                    style={{ marginRight: 78 }}
                  >
                    メールアドレス
                  </Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  <Typography>{formik.values.email}</Typography>
                </Box>
              </Box>
              {/* Gender */}
              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography
                    className={clsx(classes.label)}
                    style={{ marginRight: 78 }}
                  >
                    性別
                  </Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  <RadioGroup
                    name="gender"
                    error={Boolean(
                      formik.touched.gender && formik.errors.gender
                    )}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    row
                  >
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="女性"
                    />
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="男性"
                    />
                    <FormControlLabel
                      value="3"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="その他"
                    />
                  </RadioGroup>
                </Box>
              </Box>

              <Box mb={2} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    パスワード
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={classes.displayFlex} flexGrow={1}>
                  <TextField
                    className={clsx(classes.inputContainer, classes.input40)}
                    name="password"
                    inputRef={inputPassword}
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    type="password"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput,
                      style: {
                        fontSize: window.innerWidth <= 767 ? '0.6rem' : '1rem',
                        letterSpacing: window.innerWidth <= 767 ? 1 : -6
                      }
                    }}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <FormHelperText className={classes.textError}>
                      {formik.errors.password}
                    </FormHelperText>
                  )}
                </Box>
              </Box>

              <Box mb={3} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    パスワード確認用
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={classes.displayFlex} flexGrow={1}>
                  <TextField
                    name="password_confirmation"
                    onChange={formik.handleChange}
                    onFocus={() => setOpenSelectBirthdayOnPc(false)}
                    onBlur={formik.handleBlur}
                    value={formik.values.password_confirmation}
                    className={clsx(classes.inputContainer, classes.input40)}
                    fullWidth
                    type="password"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput,
                      style: {
                        fontSize: window.innerWidth <= 767 ? '0.6rem' : '1rem',
                        letterSpacing: window.innerWidth <= 767 ? 1 : -6
                      }
                    }}
                  />
                  {formik.errors.password_confirmation &&
                    formik.touched.password_confirmation && (
                      <FormHelperText className={classes.textError}>
                        {formik.errors.password_confirmation}
                      </FormHelperText>
                    )}
                </Box>
              </Box>
              {/* Payment */}
              <Box className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    支払い方法
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  <RadioGroup
                    name="payment_type"
                    error={Boolean(
                      formik.touched.payment_type && formik.errors.payment_type
                    )}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.payment_type}
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="クレジットカード"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="ATM決済"
                    />
                    <FormControlLabel
                      value="3"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="コンビニ決済"
                    />
                    <FormControlLabel
                      value="4"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="銀行ネット決済"
                    />
                  </RadioGroup>
                </Box>
              </Box>
              <Box className={clsx(classes.displayFlex)}>
                <Box
                  display={'flex'}
                  style={{ width: '30%' }}
                  className={clsx(classes.labelContainer)}
                ></Box>
                <Box className={clsx(classes.displayFlex)}>
                  <Typography>
                    ※クレジットカードをご選択され、その情報に変更がない場合は次回より自動更新となります。
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
              <Button
                type="submit"
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                disabled={disabledButton}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
                className={clsx(classes.registerButton)}
              >
                同意して確認ページへ
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
      {/* <DatePicker
        theme="ios"
        isOpen={isOpen}
        dateConfig={dateConfig}
        min={new Date('1900/01/01')}
        max={new Date(`${moment().subtract(1, 'years').year()}/12/31`)}
        onSelect={(e) => handleSelectOnMobile(e)}
        onCancel={() => {
          setIsOpen(false);
          document.getElementById('root').style.overflow = 'scroll';
        }}
        value={new Date(formik.values.birthday)}
        confirmText="完了"
        cancelText="取消"
      /> */}
      <AutoPaymentDialog
        open={openDialog}
        onClose={setOpenDialog}
        title={titleDialog}
        setFocus={setFocus}
      />
    </div>
  );
}
export default RegistrationInfo;
