import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Button,
  makeStyles
} from '@material-ui/core';
import FrauLogo from 'src/assets/images/logo_frau.png';
import { ROUTES } from 'src/constants';
import { checkNameTalent } from 'src/utils/auth';
import { useNavigate } from 'react-router';

import LogoSJK from '../../assets/images/sjk_logo_footer.png';
import LogoP from '../../assets/images/logo_p.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingBottom: 64
  },
  textWhite: {
    color: '#fff',
    fontSize: 16,
    '&:hover': {
      color: '#fff'
    },
    '&:after': {
      background: '#fff'
    }
  },
  footerButton: {
    position: 'relative',
    borderColor: '#fff',
    width: '100%',
    borderRadius: 24,
    padding: '11px 0',
    marginTop: 51,
    overflow: 'hidden',
    transition: 'ease .2s',
    isolation: 'isolate',
    '&:hover': {
      color: '#000'
    },
    '&:hover:before': {
      transformOrigin: 'left top',
      transform: 'scale(1, 1)'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
      background: '#fff',
      width: '100%',
      height: '100%',
      transition: 'transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s',
      transform: 'scale(0, 1)',
      transformOrigin: 'right top'
    },
    '& span': {
      zIndex: 3
    }
  },
  contentColor: {
    color: '#E8E8E8',
    fontSize: 16,
    '&:hover': {
      color: '#E8E8E8'
    },
    '&:after': {
      background: '#E8E8E8'
    }
  },
  footerTitle: {
    marginBottom: 27,
    paddingBottom: 2
  },
  footerLogoFrau: {
    marginTop: 56,
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      marginTop: 0
    }
  },
  footerBottom: {
    borderTop: '1px solid #9898984a',
    marginTop: 42,
    padding: '24px 0 40px'
  },
  footerBottomContent: {
    marginBottom: 8
  },
  footerSubLink: {
    marginBottom: 6,
    fontSize: 16
  },
  addUnderline: {
    width: 'fit-content',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      transition: 'all .3s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    }
  },
  mr: {
    marginRight: '1.3rem'
  },
  linkLogoCraft: {
    marginLeft: '1.5rem'
  },
  viewPc: {
    [theme.breakpoints.up(700)]: {
      display: 'flex'
    }
  },
  spToRow: {
    marginLeft: 'auto',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  logoTalent: {
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      display: 'none'
    }
  },
  textMinHeight: {
    '@media(min-width: 600px)': {
      display: 'none'
    }
  },
  wrapCopy: {
    display: 'flex',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column'
    }
  },
  logoP: {
    marginLeft: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
      marginTop: 10
    }
  },
  copContainer: {
    padding: '20px 0 !important',
    [theme.breakpoints.down(768)]: {
      padding: '20px !important'
    }
  }
}));

function Footer({ setting }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const { logo, logo_footer, link_logo_footer, nameLower } = checkNameTalent();

  return (
    <footer
      style={
        window.innerWidth <= 700
          ? { backgroundColor: setting.theme_color1, padding: '40px 4px 0' }
          : { backgroundColor: setting.theme_color1, paddingTop: 49 }
      }
      className={
        setting?.talent_settings?.menu_type === 2
          ? classes.footer
          : classes.contentBottom
      }
    >
      <Box>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} md={5}>
              <Box
                className={clsx(classes.logoTalent)}
                onClick={() => {
                  navigate('/');
                }}
              >
                <img
                  alt="logo"
                  src={nameLower === 'song-joongki' ? LogoSJK : logo}
                  width="274"
                />
              </Box>

              <Box className={clsx(classes.footerLogoFrau)}>
                <Box
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(
                      'https://www.frau-inter.co.jp/jp/',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  <img alt="logo" src={FrauLogo} height={60} />
                </Box>
                {logo_footer &&
                  nameLower !== 'lee-jongsuk' &&
                  nameLower !== 'yook-sungjae' &&
                  nameLower !== 'zo-insung' && (
                    <Box
                      className={clsx(classes.linkLogoCraft)}
                      onClick={() => {
                        window.open(
                          link_logo_footer,
                          '_blank',
                          'noopener,noreferrer'
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        alt="logo company"
                        src={logo_footer}
                        height={nameLower === 'zo-insung' ? 60 : 50}
                      />
                    </Box>
                  )}
                {logo_footer && nameLower === 'yook-sungjae' && (
                  <Box className={clsx(classes.linkLogoCraft)}>
                    <img
                      alt="logo company"
                      src={logo_footer}
                      height={nameLower === 'zo-insung' ? 60 : 50}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={2} className={classes.spToRow}>
              <Box className={classes.contentColor}>
                <Link href="/faq" underline="none">
                  <Typography
                    variant="h4"
                    gutterBottom
                    className={clsx(
                      classes.textWhite,
                      classes.footerTitle,
                      classes.addUnderline
                    )}
                  >
                    <strong>よくある質問</strong>
                  </Typography>
                </Link>
                {/* <Link href="/faq?q=入会方法について" underline="none">
                  <Typography
                    className={clsx(
                      classes.footerSubLink,
                      classes.contentColor,
                      classes.addUnderline
                    )}
                  >
                    入会方法について
                  </Typography>
                </Link>
                <Link href="/faq?q=会費について" underline="none">
                  <Typography
                    className={clsx(
                      classes.footerSubLink,
                      classes.contentColor,
                      classes.addUnderline
                    )}
                  >
                    会費について
                  </Typography>
                </Link>
                <Link href="/faq?q=退会方法について" underline="none">
                  <Typography className={clsx(
                    classes.footerSubLink,
                    classes.contentColor,
                    classes.addUnderline
                  )}>
                    退会方法について
                  </Typography>
                </Link> */}
              </Box>
              {/* <Box mt={2}>
                <img alt="logo" src={LogoP} width="100" />
              </Box> */}
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className={clsx(classes.contentColor)}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={clsx(
                    classes.textWhite,
                    classes.footerTitle
                    // classes.addUnderline
                  )}
                >
                  <strong>お問い合わせ</strong>
                </Typography>
                <Typography className={clsx(classes.contentColor)}>
                  よくある質問をご確認頂いた上で該当する質問がない場合や困ったこと・ご不明点がある場合は、以下のお問い合わせページよりお問い合わせください。
                </Typography>

                <Button
                  variant="outlined"
                  size="large"
                  href="/inquiry"
                  className={clsx(classes.textWhite, classes.footerButton)}
                >
                  お問い合わせ
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Grid className={clsx(classes.textWhite, classes.footerBottom)}>
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} md={7}>
                <Box className={clsx(classes.viewPc)}>
                  {/* <Link className={clsx(classes.textMinHeight)} underline="none">
                    <Typography
                      className={clsx(
                        classes.mr,
                        classes.textWhite,
                        classes.footerBottomContent
                      )}
                      style={{ fontSize: 14 }}
                    >
                      サイトマップ 
                    </Typography>
                  </Link> */}
                  <Link href={ROUTES.TERMS} underline="none">
                    <Typography
                      className={clsx(
                        classes.mr,
                        classes.textWhite,
                        classes.footerBottomContent,
                        classes.addUnderline
                      )}
                      style={{ fontSize: 14 }}
                    >
                      会員規約
                    </Typography>
                  </Link>

                  <Link
                    target="blank"
                    href={'https://www.frau-inter.co.jp/privacy-policy/'}
                    underline="none"
                  >
                    <Typography
                      className={clsx(
                        classes.mr,
                        classes.textWhite,
                        classes.footerBottomContent,
                        classes.addUnderline
                      )}
                      style={{ fontSize: 14 }}
                    >
                      プライバシーポリシー
                    </Typography>
                  </Link>

                  <Link href={ROUTES.LAW} underline="none">
                    <Typography
                      className={clsx(
                        classes.mr,
                        classes.textWhite,
                        classes.footerBottomContent,
                        classes.addUnderline
                      )}
                      style={{ fontSize: 14 }}
                    >
                      特定商取引法
                    </Typography>
                  </Link>

                  <Link href={ROUTES.SITE} underline="none">
                    <Typography
                      className={clsx(
                        classes.textWhite,
                        classes.footerBottomContent,
                        classes.addUnderline
                      )}
                      style={{ fontSize: 14 }}
                    >
                      このサイトについて
                    </Typography>
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={12} md={5} className={clsx(classes.copContainer)}>
                <Box className={clsx(classes.wrapCopy)}>
                  <Typography style={{ fontSize: 13 }} gutterBottom>
                    Copyright by FRAU INTERNATIONAL CO.,LTD All Rights Reserved.
                  </Typography>

                  <Box
                    className={clsx(classes.logoP)}
                    onClick={() => {
                      window.open('https://privacymark.jp/', '_blank');
                    }}
                  >
                    <img alt="logo" src={LogoP} height={50} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Box>
    </footer>
  );
}

Footer.propTypes = {
  bgColor: PropTypes.string
};

export default Footer;
